import { Highlight } from '@components/highlight/highlight';
import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { selectViewport } from '@redux/viewport/selectors';
import { DisplayButton } from '@xo-union/tk-ui-links';
import classNames from 'classnames';
import React from 'react';
import RfqModal from '../../../../pages/Storefront/containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { generateDefaultField } from '../../../../pages/Storefront/containers/InlineRFQ/utils';
import Styles from './location-highlights.scss';

type LocationHighlightsProps = {
	serviceArea?: string;
	travelsUpTo?: number | null;
	isAddressPublic: boolean;
};

export const LocationHighlights: React.FC<LocationHighlightsProps> = ({
	serviceArea,
	travelsUpTo,
	isAddressPublic,
}) => {
	const isMobile = useAppSelector((state) => selectViewport(state)).isMobile;
	const isUIReadabilityEnhancementsVariant = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);

	const localFormContext = useInlineRfqForm({
		freeTextLabel: '',
		headerText: 'Get a personalized quote',
		textAreaInitialValueOverride: generateDefaultField('textarea'),
		initiator: 'Travel CTA',
	});

	return (
		<div
			className={classNames(
				Styles.container,
				serviceArea && travelsUpTo ? Styles.double : Styles.single,
			)}
		>
			<RfqModal context={localFormContext} />
			{serviceArea && isAddressPublic && (
				<Highlight
					iconName="address"
					iconSize={isUIReadabilityEnhancementsVariant ? 'lg' : 'md'}
					classes={{
						container: Styles.highlight,
						title: Styles.highlightTitle,
						text: Styles.highlightText,
					}}
					highlightTitle={`${serviceArea}`}
					highlightText={
						!isMobile && isUIReadabilityEnhancementsVariant
							? 'Typically included in the starting cost'
							: 'Service area'
					}
				/>
			)}
			{travelsUpTo && (
				<Highlight
					iconName="category-trv"
					iconSize={isUIReadabilityEnhancementsVariant ? 'lg' : 'md'}
					classes={{
						container: Styles.highlight,
						title: Styles.highlightTitle,
						text: Styles.highlightText,
					}}
					highlightTitle={
						isMobile && isUIReadabilityEnhancementsVariant
							? `Up to ${travelsUpTo} miles`
							: `Travels up to ${travelsUpTo} miles`
					}
					highlightText={
						<div>
							{isMobile && isUIReadabilityEnhancementsVariant ? (
								'Travel range'
							) : (
								<>
									Additional charges may apply
									<DisplayButton
										onClick={localFormContext.openModal}
										className={Styles.highlightButton}
										type="button"
										size="sm"
										color="tertiary"
										data-testid="location-highlights-personalized-quote"
									>
										Get a personalized quote
									</DisplayButton>
								</>
							)}
						</div>
					}
				/>
			)}
		</div>
	);
};
