import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import Icon from '@xo-union/tk-component-icons';
import { DisplayLink } from '@xo-union/tk-ui-links';
import compact from 'lodash/compact';
import React from 'react';
import type { DecoratedAddress } from 'types/vendor';
import { googleMapsAPIKey } from '../../../../marketplace-web/settings/index';
import Styles from './static-map.scss';

type StaticMapProps = {
	address: DecoratedAddress;
};

const zoom = '13';
const type = 'roadmap';

export const StaticMap: React.FC<StaticMapProps> = ({ address }) => {
	const isMobile = useAppSelector(selectIsMobile);
	const isUIReadabilityEnhancementsVariant = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);
	const size = `600x${isMobile ? (isUIReadabilityEnhancementsVariant ? '350' : '200') : '245'}`;

	const formattedAddress = compact([
		address.isPublic ? address.address : '',
		address.city,
		address.state,
	]).join(', ');

	const encodedAddress = encodeURIComponent(formattedAddress);
	const staticMapSrc = `https://maps.googleapis.com/maps/api/staticmap?center=${encodedAddress}&zoom=${zoom}&scale=2&size=${size}&maptype=${type}&format=png&key=${googleMapsAPIKey}&markers=color:red%7C${encodedAddress}`;
	const linkRedirectMap = `https://maps.google.com/?q=${encodedAddress}`;

	return (
		<>
			<img src={staticMapSrc} alt={formattedAddress} loading="lazy" />
			<div className={Styles.location}>
				<Icon
					name="address"
					size={isUIReadabilityEnhancementsVariant ? 'md' : 'sm'}
				/>
				<span>{formattedAddress}</span>
			</div>
			<DisplayLink
				href={linkRedirectMap}
				color="secondary"
				size="sm"
				target="_blank"
			>
				Open map
			</DisplayLink>
		</>
	);
};
